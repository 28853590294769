import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import moment from "moment";
import { Tooltip } from "react-tippy";
import {
  ITimelineGroup,
  ITimelineItem,
  ITimelineMarker,
  TimeLineModel
} from "../../../../components/widgets/TimelineWidget/Timeline_model";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import { BaseModel } from "../../../../core/util/BaseModel";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../services/api/v2/actions/Actions.api";
import ProjectTeamUserPermissionsApi, {
  ProjectTeamUserPermissionsApi as IProjectTeamUserPermissionsApi
} from "../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../services/api/v2/projects/Projects.api";
import { ActionVisualisationsHub as IActionVisualisationsHub } from "../../../../services/hubs/ActionVisualisationsHub/ActionVisualisations_hub";
import { ImpactReportsHub as IImpactReportsHub } from "../../../../services/hubs/ImpactReportsHub/impactreports_hub";
import { ProjectAudienceVisualisationsHub as IProjectAudienceVisualisationsHub } from "../../../../services/hubs/ProjectAudienceVisualisationsHub/ProjectAudienceVisualisations_hub";
import {
  IActionInfo,
  IAudienceInfo,
  IImpactInfo,
  IStakeholderInfo
} from "../../../../services/hubs/ProjectDashboardHub/ProjectDashboard_types";
import { ProjectStakeholderVisualisationsHub as IProjectStakeholderVisualisationsHub } from "../../../../services/hubs/ProjectStakeholderVisualisationsHub/ProjectStakeholderVisualisations_hub";
import { TimelineItem } from "../../organisations/organisationDashboard/dashboardContentComponent/DashboardTimeline/components/TimelineItem";
import { Mapper } from "./ProjectView_mapper";
import { Enums } from "../../../../enums";

export class ProjectViewModel extends BaseModel {
  id: number;
  actionsProvider: IActionsApi;
  projectsApi: IProjectsApi;
  projectTeamUserPermissionsApi: IProjectTeamUserPermissionsApi;
  @observable isLoadingProject: boolean = true;
  @observable isLoadingInsights: boolean = true;
  @observable.ref projectTeam: FP.Entities.IUserPermission[] = [];
  @observable.ref project?: FP.Entities.IProject = null;
  @observable.ref insights?: FP.Entities.IProjectInsights = null;
  @observable.ref actions: IActionInfo = null;
  @observable.ref selectedMilestone?: FP.Entities.IMilestone;
  actionVisualisationsHub: IActionVisualisationsHub;
  stakeholdersHub: IProjectStakeholderVisualisationsHub;
  audienceHub: IProjectAudienceVisualisationsHub;
  impactHub: IImpactReportsHub;
  organisationId: number;
  timelineModel: TimeLineModel;
  @observable.ref stakeholders: IStakeholderInfo;
  @observable.ref audiences: IAudienceInfo;
  @observable.ref impacts: IImpactInfo;
  @observable isLoading: boolean = true;
  modalService: IModalContextModel;

  @observable impactData: any[];
  @observable impactCompletionData: any[];
  @observable impactPercentComplete: number;

  @observable ragData: any[];
  @observable actionsCompletionData: any[];
  @observable actionPercentComplete: number;

  @observable stakeholderData: any[];
  @observable audienceData: any[];

  constructor(projectId: number, orgId: number) {
    super();
    makeObservable(this);
    this.setOrganisationId(orgId);
    this.projectsApi = ProjectsApi;
    this.actionsProvider = ActionsApi;
    this.setProjectId(projectId);
    this.timelineModel = new TimeLineModel();
    this.projectTeamUserPermissionsApi = ProjectTeamUserPermissionsApi;
    this.modalService = ModalContext;
  }

  @action.bound
  async load() {
    await Promise.all([this.loadDashboardData(this.id), this.loadProject(this.id), this.loadInsights(this.id)]);
  }

  @action
  loadCharts = () => {
    this.setImpactData(Mapper.impactLevels(this.impacts));
    this.setImpactCompletionData(Mapper.impactCompletionData(this.impacts));
    this.setActionsCompletionData(Mapper.actionComplletionStatus(this.actions));
    this.setRagData(Mapper.ragData(this.actions));
    this.setStakeholderData(Mapper.stakeholderData(this.stakeholders));
    this.setAudienceData(Mapper.audienceData(this.audiences));
    this.loadActionPercentageComplete();
    this.loadImpactPercentageComplete();
    this.isLoading = false;
  };

  @action
  setImpactData = impactData => {
    this.impactData = impactData;
  };

  @action
  setImpactCompletionData = impactCompletionData => {
    this.impactCompletionData = impactCompletionData;
  };

  @action
  loadImpactPercentageComplete = () => {
    let impacts: IImpactInfo = this.impacts;
    const total = impacts?.total | 0;
    const completed = impacts.progress.completed;

    this.impactPercentComplete = Math.round(100 * (completed / total));
  };

  @action
  loadActionPercentageComplete = () => {
    let actions: IActionInfo = this.actions;
    const total = actions?.total | 0;
    // const cancelled = actions.filter(e => ProgressStatus.CANCELLED === e.progress.cancelled).length;
    const completed = actions.progress.completed;
    this.setActionPercentComplete(Math.round(100 * (completed / total)));
  };

  @action
  setActionsCompletionData = actionsCompletionData => {
    this.actionsCompletionData = actionsCompletionData;
  };

  @action
  setRagData = ragData => {
    this.ragData = ragData;
  };

  @action
  setStakeholderData = stakeholderData => {
    this.stakeholderData = stakeholderData;
  };

  @action
  setAudienceData = audienceData => {
    this.audienceData = audienceData;
  };

  onMount = async () => {
    await this.load();
  };

  onUnmount = async () => {};

  loadDashboardData = async projectId => {
    await this.projectsApi.getDashboardData(this.organisationId, projectId).then(res => {
      const { payload } = res;
      this.setActions(payload.actionInfo);
      this.setImpacts(payload.impactInfo);
      this.setStakeholders(payload.stakeholderInfo);
      this.setAudiences(payload.audienceInfo);
      this.loadCharts();
    });
  };

  loadProject = async (id: number) => {
    const res = await this.projectsApi.getById(this.organisationId, id);
    if (!res || res.isError) return;
    this.setProject(res.payload);
    this.loadProjectTeam();
  };

  loadProjectTeam = async () => {
    const res = await this.projectTeamUserPermissionsApi.getAllUsersSimple(this.organisationId, this.id);
    if (!res || res.isError) return;
    this.setProjectTeam(res.payload);
  };

  loadInsights = async (projectId: number) => {
    this.isLoadingInsights = true;
    const res = await this.projectsApi.getInsights(this.organisationId, projectId);
    if (!res || res.isError) return;
    this.setInsights(res.payload);
    this.isLoadingInsights = false;
  };

  @action
  setProject = async (project: FP.Entities.IProject) => {
    this.project = project;
    if (project) {
      this.setTimelineDefaultDates([this.project]);
      this.setTimeLineItems([this.project]);
    }
  };

  setTimelineDefaultDates = (projects: FP.Entities.IProject[]) => {
    const proj = projects.map(e => {
      return {
        ...e,
        mStartDate: moment(e.startDate),
        mEndDate: moment(e.actualEndDate)
      };
    });
    const minDate = _.minBy(proj, e => e.mStartDate);
    const maxDate = _.maxBy(proj, e => e.mEndDate);
    const startDate = minDate.mStartDate;
    const endDate = maxDate.mEndDate;
    this.timelineModel.setDefaultDates(startDate, endDate);
    this.timelineModel.setVisibleDates(startDate.subtract(10, "days"), endDate.add(10, "days"));
  };

  setTimeLineItems = (projects: FP.Entities.IProject[]) => {
    const items: ITimelineItem[] = projects.map(project => {
      return {
        id: project.id,
        group: project.id,
        title: project.name,
        start_time: moment(project.startDate),
        end_time: moment(project.actualEndDate),
        data: project
      };
    });

    const groups: ITimelineGroup[] = projects.map(project => {
      return {
        id: project.id,
        title:
          project.name.length > 16 ? (
            <Tooltip theme="light" followCursor html={<small className="d-block">{project.name}</small>}>
              {project.name}
            </Tooltip>
          ) : (
            project.name
          )
      };
    });

    const sortedItems = _.sortBy(items, (e: any) => [e.group, e.start_time]);

    const markers: ITimelineMarker[] = sortedItems
      .map((project, index) => {
        return project.data.milestones.map(milestone => {
          const cs = milestone.milestoneType
            ? `timeline-marker__icon--${Enums.Translator.MilestoneTypeKey(milestone.milestoneType)}`
            : "";
          return {
            id: milestone.id,
            date: moment(milestone.deadline),
            project: (index) * 40 + 10,
            typeClass: cs,
            title: milestone.name,
            description: milestone.description
          };
        });
      })
      .flat();
    this.timelineModel.timelineMinRows = 1;
    this.timelineModel.setItems(items);
    this.timelineModel.setGroups(groups);
    this.timelineModel.setMarkers(markers);
    this.timelineModel.sidebarWidth = 0;
    // this.timelineModel.onMarkerSelect = this.handleTimelineMarkerSelect;
    this.timelineModel.itemRenderer = TimelineItem(this.organisationId, true);
    this.timelineModel.isLoading = false;
  };

  @action
  setProjectTeam = (projectTeam: FP.Entities.IUserPermission[]) => {
    this.projectTeam = projectTeam;
  };

  @action
  setMilestone = (milestone: FP.Entities.IMilestone) => {
    this.selectedMilestone = milestone;
  };

  @action
  setInsights = (insights: FP.Entities.IProjectInsights) => {
    this.insights = insights;
  };

  @action
  setActions = (actions: IActionInfo) => {
    this.actions = actions;
  };

  @action
  setStakeholders = (stakeholders: IStakeholderInfo) => {
    this.stakeholders = stakeholders;
  };

  @action
  setAudiences = (audiences: IAudienceInfo) => {
    this.audiences = audiences;
  };

  @action
  setImpacts = (impacts: IImpactInfo) => {
    this.impacts = impacts;
  };

  showInfoModal = () => {
    // return SHOW_INFO_MODAL(this);
  };

  @action
  setImpactPercentComplete = (impactPercentComplete: any) => {
    this.impactPercentComplete = impactPercentComplete;
  };
  @action
  setActionPercentComplete = (actionPercentComplete: any) => {
    this.actionPercentComplete = actionPercentComplete;
  };
  @action
  setOrganisationId = (organisationId: number) => {
    this.organisationId = organisationId;
  };
  @action
  setProjectId = (projectId: number) => {
    this.id = projectId;
  };

  @action
  setIsLoading = isLoading => {
    this.isLoading = isLoading;
  };
}
