import { action, observable } from "mobx";
import I18n from "../../../../core/localization/I18n";
import ToasterService, { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { BaseModel } from "../../../../core/util/BaseModel";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../services/api/v2/organisations/Organisations.api";
import UsersApi, { UsersApi as IUsersApi } from "../../../../services/api/v2/users/Users.api";
import QueryStringService, {
  QUERY_STRING_PARAMS
} from "../../../../services/local/queryStringService/QueryStringService";

export class UserViewModel extends BaseModel {
  usersProvider: IUsersApi;
  organisationsProvider: IOrganisationsApi;
  toastService: IToasterService;
  organisationId: number;
  returnUrl: string;
  @observable.ref user: FP.Entities.IUser = null;

  constructor(organisationId: number) {
    super();
    this.usersProvider = UsersApi;
    this.organisationsProvider = OrganisationsApi;
    this.toastService = ToasterService;
    this.organisationId = organisationId;

    const queryStringService = QueryStringService;

    // check if there is a previous url so it redirects with the previous url in the string params
    this.returnUrl = queryStringService.getByKeyOrDefault(
      QUERY_STRING_PARAMS.RETURN_URL,
      `/organisations/${this.organisationId}/settings/users`
    );
  }

  @action
  loadUser = async (userId: string) => {
    const res = await this.usersProvider.getByIdAndOrgaisationIdAsync(this.organisationId, userId);
    if (!res || res.isError) throw new Error(res.message);
    this.setUser(res.payload);
  };

  @action
  setUser = (user: FP.Entities.IUser) => {
    this.user = user;
  };

  @action
  deleteUserCache = async (userId: string) => {
    const res = await this.organisationsProvider.deleteUserPermissionCache(this.organisationId, userId);
    if (res.payload)
      this.toastService
        .showSuccessToast(TOASTER_TOAST_TIME.SLOW)
        .setActionAlignedTitle(
          <h3 className="toaster__success-review-title">{I18n.t("phrases.userPermissionCacheDeletedTitle")}</h3>
        )
        .setContent(<span>{I18n.t("phrases.userPermissionCacheDeleted")}</span>)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);

    if (!res.payload)
      this.toastService
        .showErrorToast(TOASTER_TOAST_TIME.SLOW)
        .setActionAlignedTitle(
          <h3 className="toaster__success-review-title">{I18n.t("phrases.userPermissionCacheDeletedTitle")}</h3>
        )
        .setContent(<span>{I18n.t("phrases.userPermissionCacheNotDeleted")}</span>)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
  };

  @action
  loadOrganisationUserPermissions = async (organisationId: number, userSub: string) => {
    const res = await this.usersProvider.getOrganisationUserPermissions(organisationId, userSub);
    return res;
  };

  @action
  parseOrganisationUserPermissions = async (organisationId: number, userSub: string) => {
    const res = await this.loadOrganisationUserPermissions(organisationId, userSub);
    if (res.payload) return JSON.parse(res.payload);
  };

  @action
  getOrganisationUserProjectPermissions = async (organisationId: number, userSub: string) => {
    const res = await this.parseOrganisationUserPermissions(organisationId, userSub);
    if (res) return res[0].p;
    return [];
  };
}
