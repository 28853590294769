import { action, observable } from "mobx";
import * as React from "react";
import { ButtonTypes } from "../../../../components/ui/Button";
import { Panel } from "../../../../components/ui/Panel";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../core/localization/I18n";
import { IUiAction, UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { Animations } from "../../../../core/util/Animations";
import { BaseModel } from "../../../../core/util/BaseModel";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../services/api/v2/actions/Actions.api";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { getActionImpactFormFields } from "./ActionImpact_data";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";

export class ActionImpactsModel extends BaseModel {
  isRouteView: boolean;
  actionsProvider: IActionsApi;
  modalService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  projectId: number;
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable searchValue: string = "";
  @observable.ref action: FP.Entities.IAction;
  @observable.ref actionImpacts: FP.Entities.IImpact[] = [];
  @observable.ref filteredActionImpacts: FP.Entities.IImpact[] = [];
  organisationId: number;
  /**
   *
   */
  constructor(projectId: number, organisationId: number, isRouteView: boolean = true) {
    super();
    this.isRouteView = isRouteView;
    this.projectId = projectId;
    this.actionsProvider = ActionsApi;
    this.modalService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
    this.organisationId = organisationId;
  }

  onMount = () => {};

  onUnmount = () => {
    this.resetSearch();
  };

  @action
  setAction = (action: FP.Entities.IAction) => {
    this.action = action;
    this.actionImpacts = action.impacts as FP.Entities.IImpact[];
    this.filteredActionImpacts = action.impacts as FP.Entities.IImpact[];
    this.isLoading = false;
  };

  @action
  loadAction = async (actionId: number) => {
    this.isLoading = true;
    let res = await this.actionsProvider.getDetailedByIdAsync(this.organisationId, this.projectId, actionId);
    if (!res || res.isError) return;

    this.setAction(res.payload);
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterActionImpacts();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterActionImpacts();
    this.hideSearchMode();
  };

  @action
  filterActionImpacts = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredActionImpacts = this.actionImpacts.filter(impact => {
        const lowerName = impact.name.toLowerCase();
        const lowerRefNo = impact.refNumber.toLowerCase();

        return lowerName.includes(lowerSearch) || lowerRefNo.includes(lowerSearch);
      });
    } else {
      this.filteredActionImpacts = this.actionImpacts;
    }
  };

  showAddImpactsModal = () => {
    let formFields = getActionImpactFormFields(this.actionsProvider, this.projectId, this.organisationId, this.action);
    let formModel = new SingleFormModel();
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "action2",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          const formRes = await formModel.submit();
          if (!formRes) return;

          this.modalService.hide();

          this.httpProgress.showOverlay();
          const res = await this.actionsProvider.addImpactsAsync(
            this.organisationId,
            this.action.projectId,
            this.action.id,
            formRes.impacts
          );
          this.httpProgress.hideOverlay();

          if (!res || res.isError) return;

          if (res.payload) {
            this.loadAction(this.action.id);
          }
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
    formModel.formFields = formFields;
    formModel.actions = actions;

    return new Promise(resolve => {
      this.modalService.show({
        showClose: true,
        title: <h1 className="mt-6">{I18n.t("phrases.addImpact")}</h1>,
        content: <div className="container-fluid">{formModel.renderComponent()}</div>,
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          position: "right",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT,
          speed: 5
        },
        actions: []
      });
    });
  };
}
