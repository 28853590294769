import { AxiosRequestConfig } from "axios";
import { action, makeObservable, observable } from "mobx";

import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import HeatmapsApi, { HeatmapsApi as IHeatmapsApi } from "../../../../../services/api/v2/heatmaps/Heatmaps.api";
import { HeatmapSupportObject } from "../../organisationDashboard/models/dashboard_interfaces";
import { FullHeatmapData, FullPieData, IDrillThroughQuery } from "./Heatmap_utils";
import { SHOW_FILTER_MODAL } from "./modals/filters/HeatmapFilter_view";
import { IFlightPathApiResponse } from "../../../../../services/api/v2/BaseApiModel";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import Pages from "../../../../../routes/InsightRoutes";

export interface IHeatmapQuery {
  id: number;
  startDateMonth: number;
  startDateYear: number;
  tagIds: string;
}

interface HeatmapConfig {
  getSupportingData?: (
    organisationId: number,
    tagIds: string,
    config?: AxiosRequestConfig
  ) => Promise<IFlightPathApiResponse<HeatmapSupportObject>>;
  getHeatmapData?: (
    organisationId: number,
    query: IHeatmapQuery,
    config?: AxiosRequestConfig
  ) => Promise<IFlightPathApiResponse<FullHeatmapData>>;
  getDrillThroughPieData?: (
    organisationId: number,
    query: IDrillThroughQuery,
    config?: AxiosRequestConfig
  ) => Promise<IFlightPathApiResponse<FullPieData>>;
  getUrlForPieChart?: (
    organisationId: number,
    projectId: number,
    month: number,
    year: number,
    itemIds?: string[]
  ) => string;
}

export class HeatmapModel {
  @observable.ref currentMonth = new Date().getMonth();
  @observable.ref currentYear = new Date().getFullYear();
  @observable.ref heatmapData: FullHeatmapData;
  @observable isLoading: boolean = true;
  @observable currentView: "business-areas" | "locations" | "projects" = "business-areas";
  @observable tagFilters: FP.Entities.ITag[] = [];
  organisationId: number;
  parentId: number = 0;
  config: HeatmapConfig = {};
  heatmapProvider: IHeatmapsApi;
  httpProgress: IProgressIndicatorModel;
  modalService: IModalContextModel;

  constructor(organisationId: number, section: string) {
    makeObservable(this);
    this.currentView = section as any;
    this.heatmapProvider = HeatmapsApi;
    this.setConfig();
    this.organisationId = organisationId;
    this.httpProgress = ProgressIndicatorModel;
    this.modalService = ModalContext;
  }

  onMount = async (signal: AbortSignal) => {
    await this.loadHeatmapData(signal);
  };

  showFilterModal = () => {
    SHOW_FILTER_MODAL(this.modalService, this.organisationId, this.tagFilters, this.setTagFilters);
  };

  @action
  removeTagFilterById = (id: number) => {
    const tagIndex = this.tagFilters.map(e => e.id).indexOf(id);
    let k = this.tagFilters.slice();
    k.splice(tagIndex, 1);
    this.setTagFilters(k);
    this.loadHeatmapData();
  };

  getTagFilterIdsAsString = () => {
    if (this.tagFilters.length === 0) return "";
    let queryString = this.tagFilters.map((e, i) => e.id).join("|");
    return queryString;
  };

  getQuery = (parentId, startMonth, startYear, tagIds) => {
    const query = {} as IHeatmapQuery;
    query.id = parentId;
    query.startDateMonth = startMonth + 1;
    query.startDateYear = startYear;
    query.tagIds = tagIds;
    return query;
  };

  @action
  loadHeatmapData = async (signal?: AbortSignal) => {
    if (!this.isLoading) this.httpProgress.showTopProgressBarVisible();
    this.setIsLoading(true);
    const query = this.getQuery(this.parentId, this.currentMonth, this.currentYear, this.getTagFilterIdsAsString());
    const axiosConfig: AxiosRequestConfig = {};
    if (!!signal) {
      axiosConfig.signal = signal;
    }
    try {
      const result = await this.config.getHeatmapData(this.organisationId, query, axiosConfig);
      this.httpProgress.hideTopProgressBarVisible();
      this.setHeatmapData(result.payload);
    } catch (err) {}
  };

  //#region setters

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setParentId = parentId => {
    this.parentId = parentId;
    this.loadHeatmapData();
  };

  @action
  setCurrentYear = (year: number) => {
    this.currentYear = year;
    this.loadHeatmapData();
  };

  @action
  setCurrentMonth = (month: number) => {
    this.currentMonth = month;
    this.loadHeatmapData();
  };

  @action
  setHeatmapData = (data: FullHeatmapData) => {
    this.heatmapData = data;
    this.setIsLoading(false);
  };

  @action
  setTagFilters = async (items: any[]) => {
    this.tagFilters = items;
    this.loadHeatmapData();
  };

  setConfig = () => {
    const configs = {
      "business-areas": {
        getHeatmapData: this.heatmapProvider.getBusinessAreasHeatmap,
        getSupportingData: this.heatmapProvider.getBusinessAreasHeatmapSupportingData,
        getDrillThroughPieData: this.heatmapProvider.getBusinessAreaDrillThroughPieData,
        getUrlForPieChart: createImpactBusinessAreaUrl
      },
      locations: {
        getHeatmapData: this.heatmapProvider.getLocationsHeatmap,
        getSupportingData: this.heatmapProvider.getLocationsHeatmapSupportingData,
        getDrillThroughPieData: this.heatmapProvider.getLocationsDrillThroughPieData,
        getUrlForPieChart: createImpactLocationUrl
      },
      projects: {
        getHeatmapData: this.heatmapProvider.getProjectsHeatmap,
        getSupportingData: this.heatmapProvider.getProjectsHeatmapSupportingData,
        getDrillThroughPieData: this.heatmapProvider.getProjectDrillThroughPieData,
        getUrlForPieChart: createImpactProjectUrl
      }
    };
    this.config = configs[this.currentView];
  };
  //#endregion setters
}

const createImpactBusinessAreaUrl = (
  organisationId: number,
  projectId: number,
  month: number,
  year: number,
  itemIds?: string[]
) => {
  const busAreas = itemIds && itemIds.length > 0 ? `businessArea=${encodeURIComponent(itemIds?.join("|"))}&` : "";
  const progressStatus = `&progressStatus=In%20Progress|Not%20Started`;
  const impactsQuery = `${Pages.projects.impacts.listView.generateLink(organisationId, projectId)}`;
  const url = `${impactsQuery}?${busAreas}withinMonth=${month}&withinYear=${year}${progressStatus}`;
  return url;
};

const createImpactLocationUrl = (
  organisationId: number,
  projectId: number,
  month: number,
  year: number,
  itemIds?: string[]
) => {
  const locations = itemIds && itemIds.length > 0 ? `location=${encodeURIComponent(itemIds?.join("|"))}&` : "";
  const progressStatus = `&progressStatus=In%20Progress|Not%20Started`;
  const impactsQuery = `${Pages.projects.impacts.listView.generateLink(organisationId, projectId)}`;
  const url = `${impactsQuery}?${locations}withinMonth=${month}&withinYear=${year}${progressStatus}`;
  return url;
};

const createImpactProjectUrl = (
  organisationId: number,
  projectId: number,
  month: number,
  year: number,
  itemIds?: string[]
) => {
  const progressStatus = `&progressStatus=In%20Progress|Not%20Started`;
  const impactsQuery = `${Pages.projects.impacts.listView.generateLink(organisationId, projectId)}`;
  const url = `${impactsQuery}?withinMonth=${month}&withinYear=${year}${progressStatus}`;
  return url;
};
