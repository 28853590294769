import { action, makeObservable, observable } from "mobx";

import { NavigateFunction } from "react-router-dom";
import ModalContext from "../../../../../../../core/modalZ/context/ModalContext";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import { StakeholderType } from "../../../../../../../enums";
import Pages from "../../../../../../../routes/InsightRoutes";
import StakeholdersApi, {
  StakeholdersApi as IStakeholdersApi
} from "../../../../../../../services/api/v2/stakeholders/Stakeholders.api";
import { PieDataObject } from "../../../Heatmap/Heatmap_utils";

export class ActionCountSidebarModel extends BaseModel {
  stakeholderProvider: IStakeholdersApi;
  @observable isLoading = true;
  @observable.ref drillDownPieData: PieDataObject[];
  stakeholderId: number;
  stakeholderName: string;
  stakeholderType: StakeholderType;
  organisationId: number;
  pieColors: string[];
  navigate: NavigateFunction;

  constructor(
    organisationId: number,
    stakeholderId: number,
    stakeholderName: string,
    stakeholderType: StakeholderType,
    navigate: NavigateFunction
  ) {
    super();
    makeObservable(this);
    this.stakeholderProvider = StakeholdersApi;
    this.stakeholderId = stakeholderId;
    this.stakeholderName = stakeholderName;
    this.stakeholderType = stakeholderType;
    this.organisationId = organisationId;
    this.navigate = navigate;
    this.pieColors = [
      "#fdd886",
      "#ffc036",
      "#feae95",
      "#f49d4e",
      "#dc9700",
      "#f7820d",
      "#cc660a",
      "#966700",
      "#ed7650",
      "#ac5337",
      "#8a4100",
      "#452015"
    ];
  }

  onMount = async () => {
    await this.loadPieData();
  };

  loadPieData = async () => {
    const stakeholderProjects = await this.stakeholderProvider.getStakeholderProjectsWithActions(
      this.organisationId,
      this.stakeholderId
    );

    const res = stakeholderProjects.payload.map(stkProj => {
      return {
        id: stkProj.id,
        label: stkProj.label,
        value: stkProj.value,
        color: ""
      };
    });

    this.setModalPieData(res);
    return true;
  };

  getPieColors = () => {
    const result = [...this.pieColors];
    const zeroIndexHasValue = this.drillDownPieData[0].value > 0;
    const zeroIndexIsNoAccessProject = this.drillDownPieData[0].label === "Projects with no access";
    if (zeroIndexHasValue && zeroIndexIsNoAccessProject) result.unshift("#E5E5E5");
    return result;
  };

  @action
  setModalPieData = (data: PieDataObject[]) => {
    this.drillDownPieData = data;
    this.isLoading = false;
  };

  onPieClick = onClickData => {
    const link = `${Pages.projects.actions.listView.generateLink(
      this.organisationId,
      onClickData.id
    )}?actionStakeholders=${this.stakeholderName}&progressStatus=Not%20Started|In%20Progress`;
    this.navigate(link);
    ModalContext.hide();
  };
}
