import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ProgrammeStakeholderCapacityModel } from "./ProgrammeStakeholderCapacity_model";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../core/auth_insight/UserContext";
import I18n from "../../../../../core/localization/I18n";
import { Panel } from "../../../../../components/ui/Panel";
import "./_programme-stakeholder-capacity.scss";
import { ProgrammeStakeholderCapacityChart } from "./StakeholderCapacityChart/ProgrammeStakeholderCapacityChart_view";
import { Enums, StakeholderType, UiSizes } from "../../../../../enums";
import { ButtonGroup } from "@flightpath/coreui/dist/ui/ButtonGroup";
import { Button, ButtonTypes } from "@flightpath/coreui/dist/ui/Button";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";

export interface Props {
  programmeId: number;
}

export const ProgrammeStakeholderCapacity: React.FC<Props> = observer(props => {
  const authUser = useFlightPathUser();
  const organisationId = useCurrentOrganisationId();
  const [stakeholderType, setStakeholderType] = useState<StakeholderType>(Enums.StakeholderType.INDIVIDUAL);
  const [model] = useState(
    () => new ProgrammeStakeholderCapacityModel(organisationId, authUser, stakeholderType, props.programmeId)
  );

  useEffect(() => {
    model.onMount();
    return () => {
      if (model.isComponentMounted) {
        model.onUnmount();
      }
    };
    //eslint-disable-next-line
  }, [model]);

  useEffect(() => {
    if (stakeholderType !== model.stakeholderType) {
      model.setStakeholderType(stakeholderType);
      model.onMount();
    }
  }, [model, organisationId, stakeholderType, authUser, props.programmeId]);

  return (
    <div className="programme-capacity-view-wrapper">
      <Panel.Panel
        className={`py-4 px-4 my-4 `}
        hasBorder={true}
        background={Panel.PanelBackgrounds.BG_LIGHT}
        hasBorderRadius={true}
      >
        <div className="col">
          <div className="row">
            <div className="col-6">
              <h1>{I18n.get("phrases.capacity")}</h1>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <ButtonGroup size={UiSizes.XXS} isToggle={true} isVertical={false}>
                <Button
                  onClick={() => setStakeholderType(Enums.StakeholderType.INDIVIDUAL)}
                  type={stakeholderType !== Enums.StakeholderType.INDIVIDUAL && ButtonTypes.OUTLINE_PRIMARY}
                  isDisabled={stakeholderType === Enums.StakeholderType.INDIVIDUAL}
                >
                  {I18n.get("phrases.stakeholder")}
                </Button>
                <Button
                  onClick={() => setStakeholderType(Enums.StakeholderType.AUDIENCE)}
                  type={stakeholderType !== Enums.StakeholderType.AUDIENCE && ButtonTypes.OUTLINE_PRIMARY}
                  isDisabled={stakeholderType === Enums.StakeholderType.AUDIENCE}
                >
                  {I18n.get("phrases.audience")}
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </div>
        {model.isLoading ? (
          <PositionedSpinner></PositionedSpinner>
        ) : (
          <ProgrammeStakeholderCapacityChart
            data={[...model.stakeholderAssignmentMetrics]}
            transformer={data => ({
              data,
              tooltipLabelFn: item => {
                return `${
                  stakeholderType === Enums.StakeholderType.AUDIENCE
                    ? item.indexValue.split("-").slice(1)[0]
                    : item.indexValue.split("-").slice(1).join("")
                }`;
              },
              indexBy: e => {
                return `${e.id} - ${e.firstName} - ${e.lastName}`;
              },
              keys: ["impactCount", "actionCount"],
              colors: ["#19d3e0", "#faa082"],
              legendLabel: e => {
                return I18n.t(`phrases.${e.id}`);
              },
              leftAxisLabel: "Total count",
              groupType: "grouped",
              chartMargin: { bottom: 80 },
              bottomAxisValueFormat: (val: string) => ({
                value: `${
                  stakeholderType === Enums.StakeholderType.AUDIENCE
                    ? val.split("-").slice(1)[0]
                    : val.split("-").slice(1).join("")
                }`,
                maxLineLength: 18,
                maxLines: 3,
                truncateAtChar: 15
              }),
              bottomAxisProps: { legendOffset: 70 },
              enableGridX: true
            })}
          ></ProgrammeStakeholderCapacityChart>
        )}
      </Panel.Panel>
    </div>
  );
});
