import { ColDef } from "ag-grid-community";
import { baseTextColumn, IBaseColumnProps } from "../../../../../../../core/grids/BaseColumns";
import I18n from "../../../../../../../core/localization/I18n";
import { StakeholderType } from "../../../../../../../enums";
import { SHOW_ACTION_COUNT_MODAL, SHOW_IMPACT_COUNT_MODAL } from "../../DashboardCapacity_modal";

export const colFirstName = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "firstName",
      heading: I18n.t("phrases.organisationStakeholders_upload_firstName"),
      editable: false,
      ...props
    })
  };
};

export const colLastName = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "lastName",
      heading: I18n.t("phrases.organisationStakeholders_upload_lastName"),
      editable: false,
      ...props
    })
  };
};

export const colBusinessArea = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "businessArea",
      heading: I18n.t("phrases.organisationStakeholders_businessAreas"),
      editable: false,
      width: 400,
      ...props
    })
  };
};

export const colRole = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "role",
      heading: I18n.t("phrases.organisationStakeholders_role"),
      editable: false,
      width: 400,
      ...props
    })
  };
};

export const colImpactCount = (props?: IBaseColumnProps, stakeholderType?: StakeholderType): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "impactCount",
      heading: I18n.t("phrases.stakeholderCapacity_impactCount"),
      editable: false,
      clickEditable: true,
      width: 200,
      ...props
    }),
    onCellDoubleClicked: params => {
      if (params.data.impactCount > 0) return SHOW_IMPACT_COUNT_MODAL({ ...params.data, stakeholderType });
    }
  };
};

export const colActionCount = (props?: IBaseColumnProps, stakeholderType?: StakeholderType): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "actionCount",
      heading: I18n.t("phrases.stakeholderCapacity_actionCount"),
      editable: false,
      clickEditable: true,
      width: 200,
      ...props
    }),
    onCellDoubleClicked: params => {
      if (params.data.actionCount > 0) return SHOW_ACTION_COUNT_MODAL({ ...params.data, stakeholderType });
    }
  };
};
