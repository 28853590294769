import { ColDef } from "ag-grid-community";
import {
  colCreatedByName,
  colCreatedOn,
  colDynamicMultipleChoice,
  colImpacts,
  colLastReviewedOn,
  colModifiedByName,
  colName,
  colNextReviewDue,
  colNoteCount,
  colReviewedBy,
  colSelected,
  colTags,
  colUpdatedOn
} from "../../../../../../core/grids/CommonGridColumns";
import { IGridFns } from "../../../../../../core/grids/GridFunctions";
import I18n from "../../../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../../../core/modalZ/context/IModalContext";
import { ProjectStakeholderField, Translator } from "../../../../../../enums";
import CustomFieldsApi from "../../../../../../services/api/v2/customFields/CustomFields.api";
import {
  SHOW_ACTION_STAKEHOLDER_OWNER_MODAL,
  SHOW_STAKEHOLDER_IMPACT_MODAL,
  SHOW_STAKEHOLDER_NOTES_MODAL,
  SHOW_STAKEHOLDER_OWNER_ACTIONS_MODAL,
  SHOW_STAKEHOLDER_TAGS_MODAL
} from "../StakeholdersView_modal";
import {
  colBusinessArea,
  colCommitment,
  colEmail,
  colImpact,
  colInfluence,
  colIsKeyStakeholder,
  colOwnedActions,
  colOwners,
  colReceptiveness,
  colRole,
  colSentiment
} from "./StakeholderGridView_baseColumns";

export interface IGetStakeholderGridViewColumns {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  stakeholderId: number;
  isKeyStakeholdersOptions: FP.Generic.IKeyLabel[];
  impactLevels: FP.Generic.IKeyLabel[];
  gridFns: IGridFns;
  modalService: IModalContextModel;
  userCanViewImpacts: boolean;
  userCanViewActions: boolean;
  pinned: "left" | "right" | null;
  // modals: IImpactGridModals;
}

export const GetStakeholderGridViewColumns = (modelProps: IGetStakeholderGridViewColumns): ColDef[] => {
  let result: ColDef[] = [];
  // const modals = modelProps.modals;
  result.push(colSelected());
  result.push(
    colName(
      false,
      null,
      null,
      `/organisations/${modelProps.organisationId}/projects/${modelProps.projectId}/stakeholders`,
      { width: 150, pinned: modelProps.pinned }
    )
  );
  result.push(colEmail({ pinned: modelProps.pinned }));
  result.push(colRole());
  result.push(colBusinessArea());
  result.push(
    colOwners(modelProps.canEdit, stakeholder =>
      SHOW_STAKEHOLDER_OWNER_ACTIONS_MODAL(modelProps.modalService, modelProps.projectId, stakeholder)
    )
  );
  result.push(
    colIsKeyStakeholder(
      modelProps.canEdit,
      modelProps.gridFns.updateTextField,
      ProjectStakeholderField.isKeyStakeholder,
      modelProps.isKeyStakeholdersOptions
    )
  );
  result.push(
    colInfluence(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.influence,
      Translator.InfluenceLevelMapped()
    )
  );
  result.push(
    colImpact(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.impact,
      Translator.ImpactLevelMapped()
    )
  );
  result.push(
    colSentiment(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.sentiment,
      Translator.StakeholderProfilingMapped()
    )
  );
  result.push(
    colCommitment(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.commitment,
      Translator.StakeholderProfilingMappedSecondary()
    )
  );
  result.push(
    colReceptiveness(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.receptiveness,
      Translator.StakeholderProfilingMapped()
    )
  );

  if (modelProps.userCanViewActions) {
    result.push(
      colOwnedActions(
        modelProps.canEdit,
        SHOW_ACTION_STAKEHOLDER_OWNER_MODAL(modelProps.modalService, modelProps.projectId)
      )
    );
  }

  //Only add these columns if the current user has permissions to see the connected data
  if (modelProps.userCanViewImpacts) {
    result.push(colImpacts(modelProps.canEdit, SHOW_STAKEHOLDER_IMPACT_MODAL(modelProps.modalService)));
  }

  result.push(
    colNoteCount(modelProps.canEdit, audience => {
      SHOW_STAKEHOLDER_NOTES_MODAL(modelProps.modalService, audience);
    })
  );
  result.push(colTags(modelProps.canEdit, audience => SHOW_STAKEHOLDER_TAGS_MODAL(modelProps.modalService, audience)));
  result.push(colReviewedBy());
  result.push(colLastReviewedOn());
  result.push(colNextReviewDue());
  result.push(colCreatedByName());
  result.push(colCreatedOn());
  result.push(colModifiedByName());
  result.push(colUpdatedOn());
  return result;
};

export const GetStakeholderGridViewColumnsDictionary = (modelProps: IGetStakeholderGridViewColumns) => {
  var result = {
    name: colName(
      false,
      null,
      null,
      `/organisations/${modelProps.organisationId}/projects/${modelProps.projectId}/stakeholders`,
      { width: 150, pinned: modelProps.pinned }
    ),
    email: colEmail({ pinned: modelProps.pinned }),
    role: colRole(),
    businessArea: colBusinessArea(),
    isKeyStakeholder: colIsKeyStakeholder(
      modelProps.canEdit,
      modelProps.gridFns.updateTextField,
      ProjectStakeholderField.isKeyStakeholder,
      modelProps.isKeyStakeholdersOptions
    ),
    influence: colInfluence(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.influence,
      Translator.InfluenceLevelMapped()
    ),
    impact: colImpact(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.impact,
      Translator.ImpactLevelMapped()
    ),
    sentiment: colSentiment(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.sentiment,
      Translator.StakeholderProfilingMapped()
    ),
    commitment: colCommitment(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.commitment,
      Translator.StakeholderProfilingMappedSecondary()
    ),
    receptiveness: colReceptiveness(
      modelProps.canEdit,
      modelProps.gridFns.updateIdField,
      ProjectStakeholderField.receptiveness,
      Translator.StakeholderProfilingMapped()
    ),
    ownedActions: colOwnedActions(
      modelProps.canEdit,
      SHOW_ACTION_STAKEHOLDER_OWNER_MODAL(modelProps.modalService, modelProps.projectId)
    ),
    notes: colNoteCount(modelProps.canEdit, audience => {
      SHOW_STAKEHOLDER_NOTES_MODAL(modelProps.modalService, audience);
    }),
    tags: colTags(modelProps.canEdit, audience => SHOW_STAKEHOLDER_TAGS_MODAL(modelProps.modalService, audience)),
    owners: colOwners(modelProps.canEdit, stakeholder =>
      SHOW_STAKEHOLDER_OWNER_ACTIONS_MODAL(modelProps.modalService, modelProps.projectId, stakeholder)
    ),
    reviewedBy: colReviewedBy(),
    reviewedOn: colLastReviewedOn(),
    nextReviewDue: colNextReviewDue(),
    createdBy: colCreatedByName(),
    createdOn: colCreatedOn(),
    modifiedBy: colModifiedByName(),
    updatedOn: colUpdatedOn(),
    selected: colSelected()
  };

  //Only add these columns if the current user has permissions to see the connected data
  if (modelProps.userCanViewImpacts) {
    result["impacts"] = colImpacts(modelProps.canEdit, SHOW_STAKEHOLDER_IMPACT_MODAL(modelProps.modalService));
  }
  return result;
};

export const GenerateColumnDefinitions = (
  colDefs: FP.Entities.IColumnDef[],
  modelProps: IGetStakeholderGridViewColumns,
  defaultColFN: (props: IGetStakeholderGridViewColumns) => ColDef[]
): ColDef[] => {
  if (!colDefs || colDefs.length === 0) {
    return defaultColFN(modelProps);
  }
  const config = GetStakeholderGridViewColumnsDictionary(modelProps);
  let res = [];
  colDefs.forEach(e => {
    if (!config[e.colName] && e.isCore) {
      return;
    }
    if (e.isCore) {
      return res.push(config[e.colName]);
    }
    return res.push(GetDynamicColDef(modelProps.organisationId, modelProps.projectId, e));
  });
  if (modelProps.canEdit) {
    res.unshift(config["selected"]);
  }

  return res;
};

export const GetDynamicColDef = (organisationId: number, projectId: number, columnDef: FP.Entities.IColumnDef) => {
  const customFieldProvider = CustomFieldsApi;
  return colDynamicMultipleChoice(
    columnDef.colName,
    columnDef.header,
    true,
    async (nodeId, newValue) => {
      customFieldProvider.updateProjectStakeholderCustomFieldValue(
        organisationId,
        projectId,
        columnDef.customFieldId,
        nodeId,
        newValue
      );
    },
    1,
    [
      {
        key: 1,
        label: `1 - ${I18n.t("phrases.none")}`
      },
      {
        key: 2,
        label: `2 - ${I18n.t("phrases.poor")}`
      },
      {
        key: 3,
        label: `3 - ${I18n.t("phrases.fair")}`
      },
      {
        key: 4,
        label: `4 - ${I18n.t("phrases.good")}`
      },
      {
        key: 5,
        label: `5 - ${I18n.t("phrases.great")}`
      }
    ]
  );
};
