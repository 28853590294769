import React, { useEffect } from "react";
import { ActionImpactsModel } from "./ActionImpacts_model";
import { IF } from "../../../../components/hoc/If";
import { LinkButton, Button, ButtonIcon, ButtonTypes } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { Enums } from "../../../../enums";
import { ImpactCard } from "../../impacts/impactCard/ImpactCard_view";
import { observer } from "mobx-react";
import I18n from "../../../../core/localization/I18n";
import { Link } from "react-router-dom";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import AnimateHeight from "react-animate-height";
import { Input } from "../../../../components/ui/_forms/Input";
import { Panel } from "../../../../components/ui/Panel";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { Animations } from "../../../../core/util/Animations";
import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { CanEdit, CanView } from "../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { PermissionErrorMessage } from "../../../../contexts/permissions/PermissionMessages";

export interface ActionImpactsProps {
  model: ActionImpactsModel;
}

const ActionImpacts: React.FunctionComponent<ActionImpactsProps> = observer(props => {
  const { model } = props;
  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className={`container-fluid pt-5 ${Animations.FP_ZOOM_IN} speed-5`}>
      <CanView
        field={PermissionFields.IMPACTS}
        projectId={model.projectId}
        errorMessage={
          <div className="row mb-4">
            <div className="col">
              <PermissionErrorMessage>
                <p className="mb-0">{I18n.t("phrases.noImpactAccess")}</p>
              </PermissionErrorMessage>
            </div>
          </div>
        }
      >
        <IF condition={model.isRouteView}>
          <div className="row mb-3">
            <div className="col p-2">
              <LinkButton
                size={Enums.UiSizes.MD}
                type={ButtonTypes.LINK}
                className="p-0"
                href={`/organisations/${organisationId}/projects/${model.action.projectId}/actions/${model.action.id}`}
              >
                <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
                {I18n.t("phrases.backToImpact")}
              </LinkButton>
            </div>
          </div>
        </IF>

        <div className="row mb-4">
          <div className="col">
            <div className="d-flex justify-content-between">
              <CanEdit field={PermissionFields.IMPACTS} projectId={model.action.projectId}>
                <Button onClick={model.showAddImpactsModal}>{I18n.t("phrases.addImpact")}</Button>
              </CanEdit>

              <ButtonIcon
                className="ml-auto"
                symbol={model.isSearchMode ? IconSymbols.Close : IconSymbols.Search}
                iconSize={Enums.UiSizes.SM}
                type={ButtonTypes.OUTLINE_PRIMARY}
                onClick={model.isSearchMode ? model.resetSearch : model.showSearchMode}
              />
            </div>
            <AnimateHeight height={model.isSearchMode ? "auto" : 0} animateOpacity={true}>
              <Input
                autoFocus={true}
                size={-1}
                onChange={model.updateSearchValue}
                borderStyle="underline"
                placeholder={I18n.t("placeholders.searchImpactActions")}
                icon="search"
                value={model.searchValue}
                onBlur={null}
                className="mt-3"
              />
            </AnimateHeight>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            {model.filteredActionImpacts?.length ? (
              model.filteredActionImpacts.map(impact => {
                const mainUrl = `/organisations/${organisationId}/projects/${impact.projectId}`;
                return (
                  <Link
                    key={impact.id}
                    to={`${mainUrl}/impacts/${impact.id}?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(
                      `${mainUrl}/actions/${model.action.id}`
                    )}`}
                  >
                    <ImpactCard item={impact} />
                  </Link>
                );
              })
            ) : (
              <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
                <div>{I18n.t("phrases.noActionImpacts")}</div>
              </Panel.Panel>
            )}
          </div>
        </div>
      </CanView>
    </div>
  );
});

export { ActionImpacts };
