import { observer } from "mobx-react";
import React, { useState } from "react";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { ProgrammeSunburstModel } from "./ProgrammeSunburst_model";
import { ProgrammeDashboardSunburstChart } from "./dashboardSunburst/ProgrammeDashboardSunburstChart";

export const ProgrammeDashboardSunburstContent: React.FC<any> = observer(({ programmeId }) => {
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new ProgrammeSunburstModel(organisationId, programmeId));

  return <ProgrammeDashboardSunburstChart sunburst={model.sunburst} organisationId={organisationId} />;
});
