import { BaseModel } from "../../../../../../core/util/BaseModel";
import { action, makeObservable, observable } from "mobx";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import ChangeTeamMetricsHub, {
  ChangeTeamMetricsHub as IChangeTeamMetricsHub
} from "../../../../../../services/hubs/ChangeTeamHub/ChangeTeam_hub";

export class ChangeTeamCapacityModel extends BaseModel {
  @observable organisationId: number;
  changeTeamMetricsHub: IChangeTeamMetricsHub;
  isComponentMounted: boolean = false;
  @observable connectedUsers: any[] = [];
  @observable isLoading: boolean = true;
  authUser: FP.Entities.IUser;
  @observable changeTeamMetrics: FP.Entities.IChangeTeamMetric[];
  @observable stakeholderType: number;
  gridApi: GridApi;
  @observable searchText: string;

  constructor(organisationId: number, authUser: FP.Entities.IUser) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.changeTeamMetricsHub = ChangeTeamMetricsHub;
    this.authUser = authUser;
  }

  onMount = async () => {
    await this.registerSocketEvents();
  };

  onUnmount = () => {
    this.changeTeamMetricsHub.stopConnection();
  };

  registerSocketEvents = async () => {
    if (this.changeTeamMetricsHub.isConnectionStarted === true) {
      await this.changeTeamMetricsHub.stopConnection();
    }
    await this.changeTeamMetricsHub.startConnection();
    this.changeTeamMetricsHub.onUserJoined(d => {
      this.setConnectedUsers(d);
    });

    this.changeTeamMetricsHub.onLoadData(d => {
      this.setChangeTeamMetricsData(d);
    });

    this.changeTeamMetricsHub.onUserCellSelected(d => {
      this.setConnectedUsers(d);
    });

    await this.changeTeamMetricsHub.invokeUserJoined(this.organisationId, 0, this.authUser.sub);
    await this.changeTeamMetricsHub.invokeLoadData(this.organisationId);

    this.isComponentMounted = true;
  };

  @action
  setConnectedUsers = users => {
    this.connectedUsers = [...users];
  };

  @action
  setChangeTeamMetricsData = data => {
    this.changeTeamMetrics = data;
    this.isLoading = false;
  };

  updateData = () => {};

  isRowSelectable = () => {
    return false;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent): void => {
    this.gridApi = gridReadyEvent.api;
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };
}
