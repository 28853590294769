import { GridBaseModel } from "../common/GridBaseModel";
import { HubEvents } from "../common/HubEventsEnum";

export class ChangeTeamMetricsHub extends GridBaseModel {
  hubName: string = "change-team-metrics";

  constructor() {
    super("change-team-metrics");
  }

  onLoadData = callback => this.registerIncomingEvent(HubEvents.ON_CHANGE_TEAM_UTILISATION_GRID_DATA, callback);

  invokeLoadData = async (organisationId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_CHANGE_TEAM_UTILISATION_GRID_DATA, {
      organisationId: organisationId
    });
  };
}

const instance = new ChangeTeamMetricsHub();
export default instance;
