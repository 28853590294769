import { GridBaseModel } from "../common/GridBaseModel";
import { HubEvents } from "../common/HubEventsEnum";

export class StakeholderAssignmentMetricsHub extends GridBaseModel {
  hubName: string = "stakeholder-assignment-metrics";

  constructor() {
    super("stakeholder-assignment-metrics");
  }

  onLoadData = callback => this.registerIncomingEvent(HubEvents.ON_STAKEHOLDER_ASSIGNMENT_METRICS_GRID_DATA, callback);

  invokeLoadData = async (organisationId: number, stakeholderType: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_STAKEHOLDER_ASSIGNMENT_METRICS_GRID_DATA, {
      organisationId: organisationId,
      stakeholderType: stakeholderType
    });
  };
}

const instance = new StakeholderAssignmentMetricsHub();
export default instance;
