import React from "react";
import { SimpleAccordion } from "../../../../../components/ui/SimpleAccordion";
import I18n from "../../../../../core/localization/I18n";
import { ButtonTypes } from "../../../../../components/ui/Button";
import { UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import { Panel } from "../../../../../components/ui/Panel";
import { AccordionContent } from "./AccordionContent_view";
import { AccordionContentModel } from "./AccordionContent_model";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";

export const StakeholderAccordion: React.FC<{
  projectStakeholder: FP.Entities.IProjectStakeholder;
  stakeholderId: number;
}> = ({ projectStakeholder, stakeholderId }) => {
  const currentOrganisationId = useCurrentOrganisationId();
  return (
    <div className="stakeholder-accordion">
      <SimpleAccordion
        actions={[
          {
            id: "action1",
            label: I18n.t("phrases.viewProject"),
            hrefFn: () => {
              return `/organisations/${currentOrganisationId}/projects/${projectStakeholder.project.id}`;
            },
            componentProps: {
              type: ButtonTypes.LINK
            },
            rendersIn: UiActionRenderers.HTML_ANCHOR
          },
          {
            id: "action2",
            label: I18n.t("phrases.viewDetails"),
            hrefFn: () => {
              return `/organisations/${currentOrganisationId}/projects/${projectStakeholder.project.id}/stakeholders/${stakeholderId}`;
            },
            componentProps: {
              type: ButtonTypes.LINK
            },
            rendersIn: UiActionRenderers.HTML_ANCHOR
          }
        ]}
        header={
          <Panel.Panel>
            <div className="d-flex align-items-center mx-4">
              <h2 className="m-0">{projectStakeholder.project.name}</h2>
            </div>
          </Panel.Panel>
        }
      >
        <AccordionContent model={new AccordionContentModel(projectStakeholder)} />
      </SimpleAccordion>
    </div>
  );
};
