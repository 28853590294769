import { ButtonTypes } from "../../../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../../../components/ui/Icon";
import { Panel } from "../../../../../../../components/ui/Panel";
import {
  GridSideModalTitle,
  gridSideModalAnimationOpts,
  gridSideModalComponentProps
} from "../../../../../../../core/grids/GridSideModals";
import I18n from "../../../../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../../../../core/modalZ/context/IModalContext";
import { BenefitDescriptionModel } from "./BenefitDescription/BenefitDescription_model";
import { BenefitDescription } from "./BenefitDescription/BenefitDescription_view";

export const SHOW_BENEFIT_DESCRIPTION_SIDEPANEL = (
  modalService: IModalContextModel,
  organisationId: number,
  projectId: number
) => {
  return (benefit: FP.Entities.IBenefit) => {
    return modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={benefit.name} field={I18n.t("phrases.description")} />,
      content: (
        <BenefitDescription
          model={new BenefitDescriptionModel(modalService.hide, organisationId, projectId, benefit.id)}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };
};

export const SHOW_BENEFIT_DELETE_CONFIRM_MODAL = (
  modalService: IModalContextModel,
  benefitIds: number[],
  removeActionRange: (benefitIds: number[]) => Promise<any>
) => {
  return new Promise(resolve => {
    modalService.showConfirmDialog(
      <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
            {I18n.t("warnings.removeBenefitFromProject")}
          </div>
        </div>
        <div className="row">
          <div className="col">{I18n.t("phrases.confirmBenefitRangeRemove", { name: benefitIds.length + "" })}</div>
        </div>
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      async () => {
        modalService.hide();
        await removeActionRange(benefitIds);
        resolve(true);
      },
      () => {
        modalService.hide();
      },
      ButtonTypes.DANGER
    );
  });
};
