import _ from "lodash";
import { Enums } from "../../enums";
import { QUERY_STRING_PARAMS } from "../../services/local/queryStringService/QueryStringService";

/**
 * Selects a value from a Json object that could be as a value prop
 * ```
 * var s ={foo:{bar:"test"}}
 * GetDeepContent(s, "foo.bar");
 *
 * // returns "test"
 *
 * ```
 * @param obj Json object to be traversed for the value
 * @param selector The path to the value separated by "."
 */
export const GetDeepContent = (obj: Dictionary<any>, selector: string) => {
  var undef;
  let tree = selector.split(".");
  while (obj && tree[0]) obj = obj[tree.shift()] || undef;

  return obj;
};

//////////// BUILD TREE STRUCTURE JSON ///////

export function buildTree(data, parent?: any, selector: string = "id", depth: number = 0) {
  var result = [];
  let defaultParent = {};
  defaultParent[selector] = "";
  parent = parent ? parent : defaultParent;
  let children = _.filter(data, function (value) {
    return value.parent === parent[selector];
  });

  if (!_.isEmpty(children)) {
    _.each(children, function (child) {
      child.depth = depth;
      if (child != null) {
        let ownChildren = buildTree(data, child, selector, depth + 1);
        if (!_.isEmpty(ownChildren)) {
          child.children = ownChildren;
        }
        result.push(child);
      }
    });
  }

  return result;
}

export function deepFilterTree(arr, targetDepth) {
  const result = [];

  const traverse = (arr2, targetDepth) => {
    arr2.forEach(e => {
      if (e.depth === targetDepth) {
        result.push(e);
      } else {
        if (e.children) {
          traverse(e.children, targetDepth);
        }
      }
    });
  };

  traverse(arr, targetDepth);

  return result;
}

export function list_to_tree(list) {
  var map = {},
    node,
    roots = [],
    i;
  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }
  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentId !== "0") {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parentId]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

//////////// COMPARISON HELPERS FOR INSIGHTS ///////

export const percentageChange = (prevValue: number, newValue: number) => {
  const percentageChange = ((newValue - prevValue) / prevValue) * 100;

  if (percentageChange > 0) {
    return {
      type: Enums.ChangeType.POSITIVE,
      value: percentageChange === Infinity ? 100 : percentageChange
    };
  } else if (percentageChange < 0) {
    return {
      type: Enums.ChangeType.NEGATIVE,
      value: percentageChange
    };
  } else {
    return {
      type: Enums.ChangeType.NEUTRAL,
      value: 0
    };
  }
};

export const convertStakeholderToName = (stakeholder: Partial<FP.Entities.IStakeholder>) => {
  if (!stakeholder.stakeholderType) {
    console.error("No stakeholder type passed");
    return stakeholder.firstName;
  }

  if (stakeholder.stakeholderType === Enums.StakeholderType.INDIVIDUAL) {
    return `${stakeholder.firstName} ${stakeholder.lastName}`;
  }

  return `${stakeholder.firstName}`;
};

export const generateUserImage = (user: FP.Entities.IUser) => {
  if (!user) {
    return "";
  }
  return `https://eu.ui-avatars.com/api/?background=fff&color=00135d&name=${user.firstName}+${user.lastName}`;
};

export const systemUserOrUnassigned = (user: Partial<FP.Entities.IUser>) => {
  if (user !== null && (user.sub !== "" || typeof user.sub === "undefined"))
    return `${user.firstName} ${user.lastName}`;
  return "";
};

export const getOrgStakeholderLink = (organisationId: number, stakeholderId: number) => {
  return `/organisations/${organisationId}/stakeholders/${stakeholderId}?${
    QUERY_STRING_PARAMS.RETURN_URL
  }=${encodeURIComponent(window.location.href.replace(appConfig.baseUrl, ""))}`;
};

export const makeArrayFromString = (stringData: string, divider: string): string[] => {
  if (stringData) return stringData.split(divider);

  return [];
};
