import { action, makeObservable, observable } from "mobx";
import { NavigateFunction } from "react-router-dom";
import { PieDataObject } from "../../Heatmap_utils";
import ModalContext from "../../../../../../../core/modalZ/context/ModalContext";

export class PieChartModel {
  @observable isLoading = true;
  @observable.ref drillDownPieData: PieDataObject[];
  rowChildren: string[] = [];
  getChartData: any;
  rowId: any;
  month: any;
  year: any;
  organisationId: any;
  getTagFilterIdsAsString: any;
  sectionView: string;
  getUrl: (organisationId: number, projectId: number, month: number, year: number, itemIds?: string[]) => string;
  rowName: string;
  navigate: NavigateFunction;

  /**
   *
   */
  constructor(
    organisationId,
    getChartData,
    rowId,
    month,
    year,
    getTagFilterIdsAsString,
    getUrl: (organisationId: number, projectId: number, month: number, year: number, itemIds?: string[]) => string,
    rowName: string,
    navigate: NavigateFunction
  ) {
    makeObservable(this);
    this.getChartData = getChartData;
    this.rowId = rowId;
    this.navigate = navigate;
    this.month = month;
    this.year = year;
    this.organisationId = organisationId;
    this.getTagFilterIdsAsString = getTagFilterIdsAsString;
    this.getUrl = getUrl;
    this.rowName = rowName;
  }

  onMount = async () => {
    await this.loadPieData();
  };

  loadPieData = async () => {
    const query = this.drillDownQuery(this.rowId, this.month, this.year, this.getTagFilterIdsAsString());
    const result = await this.getChartData(this.organisationId, query);

    if (result.payload.tableFilterData) {
      this.setRowChildren(result.payload.tableFilterData.names);
    }
    this.setModalPieData(result.payload.pieDataList);
    return result.payload.pieDataList;
  };

  @action
  setModalPieData = (data: PieDataObject[]) => {
    this.drillDownPieData = data;
    this.isLoading = false;
  };

  drillDownQuery = (businessAreaId: number, month: number, year: number, tagIds: string) => {
    return {
      id: businessAreaId,
      month,
      year,
      tagIds
    };
  };

  onPieClick = onClickData => {
    if (onClickData.id > 0) {
      // const url = this.createImpactTableQueryUrl(onClickData?.id);
      const s = this.rowChildren.slice();
      s.push(this.rowName);
      const url = this.getUrl(this.organisationId, onClickData.id, this.month, this.year, s);
      this.navigate(url);
      ModalContext.hide();
    }
  };

  getPipeSeparatedQueryString = (queries: any[]) => {
    let result = "";

    queries.forEach((e, i) => {
      let separator = "|";
      if (i + 1 === queries.length) separator = "";
      result = result + e + separator;
    });

    const encodedResult = encodeURIComponent(result);
    return encodedResult;
  };

  setRowChildren = async (data: string[]) => {
    this.rowChildren = data;
  };
}
