import { ColDef } from "ag-grid-community";
import {
  colFirstName,
  colLastName,
  colBusinessArea,
  colRole,
  colImpactCount,
  colActionCount
} from "./StakeholderCapacityGrid_Grid_Config";
import { Enums } from "../../../../../../../enums";
import I18n from "../../../../../../../core/localization/I18n";

export const GetStakeholderCapacityGridViewColumns = (stakeholderType: number): ColDef[] => {
  let result: ColDef[] = [];
  if (stakeholderType === Enums.StakeholderType.INDIVIDUAL) {
    result.push(colFirstName());
    result.push(colLastName());
    result.push(colBusinessArea());
    result.push(colRole());
  } else {
    result.push(colFirstName({ heading: I18n.t("phrases.name") }));
    result.push(colBusinessArea());
  }
  result.push(colImpactCount(null, stakeholderType));
  result.push(colActionCount(null, stakeholderType));

  return result;
};
