import { action, makeObservable, observable, runInAction } from "mobx";
import { ButtonTypes } from "../../../../components/ui/Button";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { BaseModel } from "../../../../core/util/BaseModel";
import ActionTypesApi, {
  ActionTypesApi as IActionTypesApi
} from "../../../../services/api/v2/actionTypes/ActionTypes.api";
import ImpactGroupsApi, {
  ImpactGroupsApi as IImpactGroupsApi
} from "../../../../services/api/v2/impactGroups/ImpactGroups.api";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../services/api/v2/impacts/Impacts.api";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../services/api/v2/organisations/Organisations.api";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";
import LocalStorageService from "../../../../services/local/localStorageService/LocalStorageService";
import QueryStringService, {
  QUERY_STRING_PARAMS
} from "../../../../services/local/queryStringService/QueryStringService";
import { getImpactGroupForm } from "../../forms/impactGroups/ImpactGroupFormSection_data";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import Pages from "../../../../routes/InsightRoutes";

interface IEditImpactGropViewModelOptions {
  impactGroupId: number;
  projectId: number;
  organisationId: number;
  user: FP.Entities.IUser;
}

export class EditImpactGroupViewModel extends BaseModel {
  localStorageService: ILocalStorageService;
  projectId: number;
  impactGroupId: number;
  impactProvider: IImpactsApi;
  orgId: number;
  orgProvider: IOrganisationsApi;
  impactGroupProvider: IImpactGroupsApi;
  httpProgress: IProgressIndicatorModel;
  actionTypeProvider: IActionTypesApi;
  @observable isLoading: boolean = true;
  @observable.ref impactGroup: FP.Entities.IImpactGroup;
  @observable.ref formModel: SingleFormModel;
  user: FP.Entities.IUser;
  returnUrl: string;

  constructor({ projectId, impactGroupId, user, organisationId }: IEditImpactGropViewModelOptions) {
    super();
    makeObservable(this);
    this.projectId = projectId;
    this.impactGroupId = impactGroupId;
    this.user = user;

    this.impactProvider = ImpactsApi;
    this.impactGroupProvider = ImpactGroupsApi;
    this.orgProvider = OrganisationsApi;
    this.localStorageService = LocalStorageService;
    this.actionTypeProvider = ActionTypesApi;
    this.httpProgress = ProgressIndicatorModel;
    this.formModel = new SingleFormModel();
    this.orgId = organisationId;

    const queryStringService = QueryStringService;

    // check if there is a previous url so it redirects with the previous url in the string params
    const prevUrl = queryStringService.getByKeyOrDefault(QUERY_STRING_PARAMS.PREV_RETURN_URL, "");
    this.returnUrl = queryStringService.getByKeyOrDefault(
      QUERY_STRING_PARAMS.RETURN_URL,
      `${Pages.projects.impactGroups.extendedView.generateLink(this.orgId, this.projectId, impactGroupId)}${
        prevUrl ? "?" + QUERY_STRING_PARAMS.RETURN_URL + "=" + encodeURIComponent(prevUrl) : ""
      }`
    );
  }

  onMount = async () => {
    await this.loadImpactGroup();
    this.formModel.formFields = getImpactGroupForm(this.impactProvider, this.projectId, this.orgId, this.impactGroup);

    this.formModel.actions = [
      {
        id: "cancel",
        label: I18n.t("phrases.cancel"),
        rendersIn: UiActionRenderers.LINK_BUTTON,
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto",
          href: `/organisations/${this.orgId}/projects/${this.projectId}/impactGroups/${this.impactGroupId}`
        }
      },
      {
        id: "save",
        label: I18n.t("phrases.save"),
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.PRIMARY,
          className: "ml-2"
        },
        onAction: this.updateImpactGroup
      }
    ];
  };

  onUnmount = () => {};

  @action
  loadImpactGroup = async () => {
    let res = await this.impactGroupProvider.getDetailed(this.orgId, this.projectId, this.impactGroupId);
    let impactsRes = await this.impactGroupProvider.getImpactGroupImpacts(
      this.orgId,
      this.projectId,
      this.impactGroupId
    );
    if (!res || res.isError || !impactsRes || impactsRes.isError) return;

    runInAction(() => {
      this.impactGroup = res.payload;
      this.impactGroup.impacts = impactsRes.payload;
    });
  };

  @action
  updateImpactGroup = async () => {
    this.formModel.isSaving = true;
    let res = await this.formModel.submit();
    if (!res) return;
    res.refNumber = (this.impactGroup as any).refNumber;
    this.httpProgress.showOverlay();
    let result = await this.impactGroupProvider.update(
      this.orgId,
      this.projectId,
      this.impactGroupId,
      res as FP.Entities.IImpactGroup
    );
    this.httpProgress.hideOverlay();

    if (!result || result.isError) return;

    this.impactGroup = result.payload;
    // appHistory.push(this.returnUrl);
  };
}
