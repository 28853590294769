import { HubEvents } from "../common/HubEventsEnum";
import { SignalRBaseModel } from "../common/SignalRBaseModel";

export class ImpactReportsHub extends SignalRBaseModel {
  hubName: string = "impact-reports";

  constructor() {
    super("impact-reports");
  }

  onData = callback => this.registerIncomingEvent(HubEvents.ON_DATA, callback);

  invokeLoadData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };

  onImpactsByTypeChartData = callback => this.registerIncomingEvent(HubEvents.ON_IMPACTS_BY_TYPE_CHART_DATA, callback);

  InvokeImpactReportByTypeData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_IMPACT_REPORT_BY_TYPE_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };

  onImpactsByLocationChartData = callback =>
    this.registerIncomingEvent(HubEvents.ON_IMPACTS_BY_LOCATION_CHART_DATA, callback);

  InvokeImpactReportByLocationData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_IMPACT_REPORT_BY_LOCATION_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };

  onImpactsByBusinessAreaChartData = callback =>
    this.registerIncomingEvent(HubEvents.ON_IMPACTS_BY_BUSINESS_AREA_CHART_DATA, callback);

  InvokeImpactReportByBusinessAreaData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_IMPACT_REPORT_BY_BUSINESS_AREA_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };

  onImpactsByOwnerChartData = callback =>
    this.registerIncomingEvent(HubEvents.ON_IMPACTS_BY_OWNER_CHART_DATA, callback);

  InvokeImpactReportByOwnerData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_IMPACT_REPORT_BY_OWNER_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };

  onImpactsByStakeholderSentimentChartData = callback =>
    this.registerIncomingEvent(HubEvents.ON_IMPACTS_BY_STAKEHOLDER_SENTIMENT_CHART_DATA, callback);

  InvokeImpactReportByStakeholderSentimentData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_IMPACT_REPORT_BY_STAKEHOLDER_SENTIMENT_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };

  onImpactsByStakeholderCommitmentChartData = callback =>
    this.registerIncomingEvent(HubEvents.ON_IMPACTS_BY_STAKEHOLDER_COMMITMENT_CHART_DATA, callback);

  InvokeImpactReportByStakeholderCommitmentData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_IMPACT_REPORT_BY_STAKEHOLDER_COMMITMENT_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };

  onImpactsByStakeholderReceptivenessChartData = callback =>
    this.registerIncomingEvent(HubEvents.ON_IMPACTS_BY_STAKEHOLDER_RECEPTIVENESS_CHART_DATA, callback);

  InvokeImpactReportByStakeholderReceptivenessData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_IMPACT_REPORT_BY_STAKEHOLDER_RECEPTIVENESS_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };
}

const instance = new ImpactReportsHub();
export default instance;
