import { GridApi, GridReadyEvent } from "ag-grid-community";
import { action, makeObservable, observable } from "mobx";
import { BaseModel } from "../../../../../../core/util/BaseModel";
import StakeholderAssignmentMetricsHub, {
  StakeholderAssignmentMetricsHub as IStakeholderAssignmentMetricsHub
} from "../../../../../../services/hubs/StakeholderAssignmentMetricsHub/StakeholderAssignmentMetrics_hub";

export class StakeholderCapacityModel extends BaseModel {
  @observable organisationId: number;
  stakeholderAssignmentMetricsHub: IStakeholderAssignmentMetricsHub;
  isComponentMounted: boolean = false;
  @observable connectedUsers: any[] = [];
  @observable isLoading: boolean = true;
  authUser: FP.Entities.IUser;
  @observable stakeholderAssignmentMetrics: FP.Entities.IStakeholderAssignmentMetric[];
  @observable stakeholderType: number;
  gridApi: GridApi;
  @observable searchText: string;

  constructor(organisationId: number, authUser: FP.Entities.IUser, stakeholderType: number) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.stakeholderAssignmentMetricsHub = StakeholderAssignmentMetricsHub;
    this.authUser = authUser;
    this.stakeholderType = stakeholderType;
  }

  onMount = async () => {
    await this.registerSocketEvents();
  };

  onUnmount = () => {
    this.stakeholderAssignmentMetricsHub.stopConnection();
  };

  registerSocketEvents = async () => {
    if (this.stakeholderAssignmentMetricsHub.isConnectionStarted === true) {
      await this.stakeholderAssignmentMetricsHub.stopConnection();
    }
    await this.stakeholderAssignmentMetricsHub.startConnection();
    this.stakeholderAssignmentMetricsHub.onUserJoined(d => {
      this.setConnectedUsers(d);
    });

    this.stakeholderAssignmentMetricsHub.onLoadData(d => {
      this.setStakeholderAssignmentMetricsData(d);
    });

    this.stakeholderAssignmentMetricsHub.onUserCellSelected(d => {
      this.setConnectedUsers(d);
    });

    await this.stakeholderAssignmentMetricsHub.invokeUserJoined(this.organisationId, 0, this.authUser.sub);
    await this.stakeholderAssignmentMetricsHub.invokeLoadData(this.organisationId, this.stakeholderType);

    this.isComponentMounted = true;
  };

  @action
  setConnectedUsers = users => {
    this.connectedUsers = [...users];
  };

  @action
  setStakeholderAssignmentMetricsData = data => {
    this.stakeholderAssignmentMetrics = data;
    this.isLoading = false;
  };

  updateData = () => {};

  isRowSelectable = () => {
    return false;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent): void => {
    this.gridApi = gridReadyEvent.api;
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };
}
