import { UiSizes } from "@flightpath/coreui/dist/enums";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import { Panel } from "@flightpath/coreui/dist/ui";
import { Icon } from "@flightpath/coreui/dist/ui/Icon";
import { Pill, PillTypes } from "@flightpath/coreui/dist/ui/Pill";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { Tooltip, TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";
import moment from "moment";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { Timeline } from "../../../../components/widgets/TimelineWidget";
import { RenderXssSafeString } from "../../../../core/AntiXss/AntiXssHelper";
import I18n from "../../../../core/localization/I18n";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import { Enums, ProgressStatus } from "../../../../enums";
import Pages from "../../../../routes/InsightRoutes";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { ProgrammeLocations } from "../view/ProgrammeLocations";
import { ProgrammeSponsor } from "../view/ProgrammeSponsor";
import { ProgrammeContacts } from "../view/ProjectContacts";

export interface ProgrammeSidePanelProps {
  programmeDashboardData?: FP.Entities.IProgrammeDashboard;
}

export const ProgrammeSidePanel: React.FC<ProgrammeSidePanelProps> = ({ programmeDashboardData }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId, programmeId } = useParams<{ projectId: string; organisationId: string; programmeId: string }>();

  if (!programmeDashboardData) {
    return <PositionedSpinner />;
  }

  let pillType =
    programmeDashboardData.programmeDetails.progressStatus === ProgressStatus.NOT_STARTED
      ? PillTypes.LIGHT_GRAY
      : PillTypes.GRAY;

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="mb-5">
            <h2>{I18n.t("phrases.overview")}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: RenderXssSafeString(programmeDashboardData.programmeDetails.description)
              }}
            />
          </div>
          <div className="mb-5">
            <h2>{I18n.t("phrases.objectives")}</h2>
            {programmeDashboardData.programmeDetails.objectives ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: RenderXssSafeString(programmeDashboardData.programmeDetails.objectives)
                }}
              ></div>
            ) : (
              <div>{I18n.t("phrases.noObjectives")}</div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="row">
            <div className="col-lg-12 mb-5">
              <div className="mb-2 d-flex align-items-center">
                <Icon symbol={IconSymbols.Stakeholders} size={UiSizes.MD} className="mr-1" />
                <h2 className="m-0">{I18n.t("phrases.programmeContacts")}</h2>
              </div>
              <ProgrammeContacts organisationId={organisationId} contacts={programmeDashboardData.programmeContacts} />
            </div>
            <div className="col-lg-12 mb-5">
              <div className="mb-2 d-flex align-items-center">
                <Icon symbol={IconSymbols.Stakeholders} size={UiSizes.MD} className="mr-1" />
                <h2 className="m-0">{I18n.t("phrases.programmeSponsor")}</h2>
              </div>
              <ProgrammeSponsor
                organisationId={organisationId}
                programmeSponsor={programmeDashboardData.programmeDetails.programmeSponsor}
              />
            </div>
            <div className="col-lg-12 mb-5">
              <div className="d-flex flex-column" style={{ height: "100%" }}>
                <span className="mb-2 d-flex align-items-center">
                  <Icon symbol={IconSymbols.Location} size={UiSizes.MD} className="mr-1" />
                  <h2 className="mb-0">{I18n.t("phrases.locations")}</h2>
                  <Tooltip
                    className="table__tooltip"
                    triggeredOn="click"
                    position={TooltipPositions.LEFT}
                    shownElement={<Icon symbol={IconSymbols.HelpCircle} size={UiSizes.SM} className="ml-2" />}
                  >
                    <Panel.Panel
                      className="p-2"
                      style={{ width: 300 }}
                      background={Panel.PanelBackgrounds.BG_WHITE}
                      hasShadow={true}
                      hasBorderRadius={true}
                    >
                      {I18n.t("phrases.programmeDashboard_LocationHelp")}
                    </Panel.Panel>
                  </Tooltip>
                </span>

                <Panel.Panel
                  hasShadow={true}
                  hasBorderRadius={true}
                  type={Panel.PanelTypes.OUTLINES}
                  className="d-flex flex-column justify-content-center p-3"
                  style={{ flex: 1 }}
                >
                  <ProgrammeLocations
                    programmeLocations={programmeDashboardData.programmeLocations}
                    organisationId={organisationId}
                  />
                  <hr />
                  <div className="d-flex flex-column align-items-end">
                    <Link
                      to={Pages.projects.programmes.locations.generateLink(organisationId, projectId, programmeId)}
                      onClick={() => ModalContext.hide()}
                      className="d-flex flex-row align-items-center text-right"
                    >
                      <span>{I18n.t("phrases.locationDetails")}</span>{" "}
                      <Icon symbol={IconSymbols.ArrowRightCircle} size={UiSizes.SM} className="ml-1" />
                    </Link>
                  </div>
                </Panel.Panel>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 z-index-1">
              <div className="programme-overview__progress">
                <h2 className="mb-0">{I18n.t("phrases.programmeTimeline")}</h2>
                <Pill data-testid="progress-status-label" size={UiSizes.SM} type={pillType}>
                  {Enums.Translator.ProgrammeProgressStatus(programmeDashboardData.programmeDetails.progressStatus)}
                </Pill>
              </div>
              <Timeline
                startDate={new Date(programmeDashboardData.programmeDetails.startDate)}
                initialEndDate={new Date(programmeDashboardData.programmeDetails.endDate)}
                actualEndDate={new Date(programmeDashboardData.programmeDetails.endDate)}
              />
            </div>
            <div className="col-6 mt-2">
              <p>{moment(programmeDashboardData.programmeDetails.startDate).format("L")}</p>
            </div>
            <div className="col-6 mt-2">
              <p className="text-right">{moment(programmeDashboardData.programmeDetails.endDate).format("L")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
