import { observable } from "mobx";
import { BaseModel } from "../../../../../core/util/BaseModel";

export class ChangeImpactAssessmentModel extends BaseModel {
  organisationId: number;
  projectId: number;
  @observable isLoading: boolean = true;
  @observable allImpacts: FP.Entities.IImpactAssessmentDataGroup[];

  constructor(data: FP.Entities.IImpactAssessmentDataGroup[], organisationId: number, projectId: number) {
    super();
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.allImpacts = data;
  }
}
