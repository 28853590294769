import { AxiosInstance, AxiosRequestConfig } from "axios";
import { FilteredProjectApiModel } from "../filteredApi/FilteredProjectApiModel";
import http from "../Http";
import { IFlightPathApiResponse } from "../BaseApiModel";

export class CustomFieldsApi extends FilteredProjectApiModel<FP.Entities.IColumnDef> {
  controller: string = "custom-fields";

  constructor(http: AxiosInstance) {
    super(http, "custom-fields");
  }

  updateImpactCustomFieldValue = async (
    organisationId: number,
    projectId: number,
    customFieldId: number,
    impactId: number,
    value: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/custom-fields/${customFieldId}/projects/${projectId}/impacts/${impactId}`;
    let res = await this.http.put(url, { value }, config);
    return res.data;
  };

  updateProjectStakeholderCustomFieldValue = async (
    organisationId: number,
    projectId: number,
    customFieldId: number,
    projectStakeholderId: number,
    value: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/custom-fields/${customFieldId}/projects/${projectId}/project-stakeholders/${projectStakeholderId}`;
    let res = await this.http.put(url, { value }, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };
}

const instance = new CustomFieldsApi(http);
export default instance;
