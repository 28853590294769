import { gridSideModalAnimationOpts, gridSideModalComponentProps } from "../../../../../core/grids/GridSideModals";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import { BusinessAreasSidebar } from "./modals/businessAreasSidebar/BusinessAreasSidebar_view";

export const SHOW_BUSINESS_AREA_SELECTION_MODAL = (
  updateBusinessAreas: (businessAreas: number[]) => void,
  currentIds: number[]
) => {
  ModalContext.show({
    showClose: true,
    title: (
      <div className="mt-6">
        <h3 className="mb-5">Business Areas</h3>
      </div>
    ),
    content: <BusinessAreasSidebar updateBusinessAreas={updateBusinessAreas} currentIds={currentIds} />,
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};
