import { action, makeObservable, observable } from "mobx";
import { ButtonTypes } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Panel } from "../../../../../components/ui/Panel";
import { IPaginationModel, PaginationModel } from "../../../../../components/widgets/pagination/Pagination_model";
import { FilterModel, FilterOperator, IFilterAttribute, IFilterModel } from "../../../../../core/filter/Filter_model";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../../core/localization/I18n";
import { ITableModel } from "../../../../../core/table/ITableModel";
import { TableModel } from "../../../../../core/table/Table_model";
import { UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import { Animations } from "../../../../../core/util/Animations";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { Enums } from "../../../../../enums";
import { IFlightPathApiResponse } from "../../../../../services/api/v2/BaseApiModel";
import ImpactTypesApi, {
  ImpactTypesApi as IImpactTypesApi
} from "../../../../../services/api/v2/impactTypes/ImpactTypes.api";
import { getImpactTypeFormFields } from "../../../forms/impactType/ImpactTypeFormFields_data";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { GetImpactTypeTableConfig } from "./ImpactTypeSettings_config";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";

export class ImpactTypeSettingsModel extends BaseModel {
  organisationId: number;
  tableModel: ITableModel<FP.Entities.IImpactType>;
  paginationModel: IPaginationModel;
  formModel: SingleFormModel;
  filterModel: IFilterModel<FP.Entities.IImpactType>;
  @observable.ref impactTypes: FP.Entities.IImpactType[];
  impactTypeProvider: IImpactTypesApi;
  httpProgress: IProgressIndicatorModel;
  modalService: IModalContextModel;

  constructor(organisationId: number) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.modalService = ModalContext;
    this.impactTypeProvider = ImpactTypesApi;
    this.httpProgress = ProgressIndicatorModel;

    this.formModel = new SingleFormModel();
    this.installPagination();
    this.installFilter();
    this.initTable();
  }

  loadImpactTypes = async () => {
    await this.filterModel.loadData();
  };

  @action
  initTable = () => {
    this.tableModel = new TableModel();
    const tableConfig = GetImpactTypeTableConfig(this);
    this.tableModel.set(tableConfig);
  };

  loadPageData = (index: number) => {
    this.filterModel.setConfig({ page: index });
    this.filterModel.loadData();
  };

  installFilter = () => {
    const config = {
      paginationModel: this.paginationModel,
      initOpts: {
        filterCb: async filterOptions => await this.impactTypeProvider.getFiltered(this.organisationId, filterOptions)
      }
    };
    this.filterModel = new FilterModel(config);

    const organisationFilter: IFilterAttribute = {
      key: "organisationId",
      value: [this.organisationId + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    const nameFilter: IFilterAttribute = {
      key: "name",
      value: [],
      operator: FilterOperator.CONTAINS
    };

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    this.filterModel.addSort({
      key: "name",
      isAsc: true
    });

    this.filterModel.addFilter(organisationFilter);
    this.filterModel.addFilter(nameFilter);
    this.filterModel.addFilter(lifeCycleFilter);
    this.filterModel.setConfig({
      formFields: null,
      onDataLoaded: this.setImpactTypes
    });
  };

  @action
  setImpactTypes = (impactTypes: FP.Entities.IImpactType[]) => {
    this.impactTypes = impactTypes;
    this.tableModel.setData(impactTypes);
  };

  installPagination = () => {
    this.paginationModel = new PaginationModel();
    this.paginationModel.setConfig({ onPageClick: this.loadPageData });
  };

  showFormModal = (impactType?: FP.Entities.IImpactType) => {
    this.formModel.formFields = getImpactTypeFormFields(this.organisationId, impactType);
    this.formModel.actions = [
      {
        id: "cancel",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "CreateActionTypeButton",
        label: I18n.t("phrases.save"),
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          className: "ml-2"
        },
        onAction: async () => {
          const formRes = await this.formModel.submit();
          if (!formRes) return;

          let res: IFlightPathApiResponse<FP.Entities.IActionType>;

          this.modalService.hide();
          this.httpProgress.showOverlay();
          if (impactType) {
            res = await this.impactTypeProvider.update(
              this.organisationId,
              impactType.id,
              formRes as FP.Entities.IImpactType
            );
          } else {
            res = await this.impactTypeProvider.create(this.organisationId, formRes as FP.Entities.IImpactType);
          }
          this.httpProgress.hideOverlay();

          if (!res || res.isError) return;

          this.loadImpactTypes();
        }
      }
    ];

    this.modalService.show({
      title: <h4 className="mt-6">{I18n.t("phrases.createImpactType")}</h4>,
      showClose: true,
      content: <div className="container-fluid">{this.formModel.renderComponent()}</div>,
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT,
        speed: 5
      }
    });
  };

  showConfirmDeleteModal = (impactType: FP.Entities.IImpactType) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.deleteImpactType")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmDelete", { name: impactType.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          this.modalService.hide();

          this.httpProgress.showOverlay();
          const res = await this.impactTypeProvider.remove(this.organisationId, impactType.id);
          this.httpProgress.hideOverlay();

          if (!res || res.isError) return;

          this.loadImpactTypes();
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };
}
