import { action, makeObservable, observable } from "mobx";

import { BaseModel } from "../../../../../../../core/util/BaseModel";
import { PieDataObject } from "../../../Heatmap/Heatmap_utils";
import ModalContext from "../../../../../../../core/modalZ/context/ModalContext";
import Pages from "../../../../../../../routes/InsightRoutes";
import UsersApi, { UsersApi as IUsersApi } from "../../../../../../../services/api/v2/users/Users.api";
import { NavigateFunction } from "react-router-dom";

export class ChangeTeamActionCountSidebarModel extends BaseModel {
  userProvider: IUsersApi;
  @observable isLoading = true;
  @observable.ref drillDownPieData: PieDataObject[];
  userId: number;
  userName: string;
  organisationId: number;
  pieColors: string[];
  navigate: NavigateFunction;

  constructor(organisationId: number, userId: number, userName: string, navigate: NavigateFunction) {
    super();
    makeObservable(this);
    this.userProvider = UsersApi;
    this.userId = userId;
    this.userName = userName;
    this.organisationId = organisationId;
    this.navigate = navigate;
    this.pieColors = [
      "#fdd886",
      "#ffc036",
      "#feae95",
      "#f49d4e",
      "#dc9700",
      "#f7820d",
      "#cc660a",
      "#966700",
      "#ed7650",
      "#ac5337",
      "#8a4100",
      "#452015"
    ];
  }

  onMount = async () => {
    await this.loadPieData();
  };

  loadPieData = async () => {
    const stakeholderProjects = await this.userProvider.getProjectsWithActions(this.organisationId, this.userId);

    const res = stakeholderProjects.payload.map(stkProj => {
      return {
        id: stkProj.id,
        label: stkProj.label,
        value: stkProj.value,
        color: ""
      };
    });

    this.setModalPieData(res);
    return true;
  };

  getPieColors = () => {
    const result = [...this.pieColors];
    const zeroIndexHasValue = this.drillDownPieData[0].value > 0;
    const zeroIndexIsNoAccessProject = this.drillDownPieData[0].label === "Projects with no access";
    if (zeroIndexHasValue && zeroIndexIsNoAccessProject) result.unshift("#E5E5E5");
    return result;
  };

  @action
  setModalPieData = (data: PieDataObject[]) => {
    this.drillDownPieData = data;
    this.isLoading = false;
  };

  onPieClick = onClickData => {
    this.navigate(
      `${Pages.projects.actions.listView.generateLink(this.organisationId, onClickData.id)}?owner=${this.userName}`
    );
    ModalContext.hide();
  };
}
