import { ColDef } from "ag-grid-community";
import { baseTextColumn, IBaseColumnProps } from "../../../../../../../core/grids/BaseColumns";
import I18n from "../../../../../../../core/localization/I18n";
import {
  SHOW_AUDIENCE_COUNT_MODAL,
  SHOW_PROJECT_TEAM_COUNT_MODAL,
  SHOW_STAKEHOLDER_COUNT_MODAL,
  SHOW_USER_ACTION_COUNT_MODAL,
  SHOW_USER_IMPACT_COUNT_MODAL
} from "../../DashboardCapacity_modal";

export const colFirstName = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "firstName",
      heading: I18n.t("phrases.organisationStakeholders_upload_firstName"),
      editable: false,
      ...props
    })
  };
};

export const colLastName = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "lastName",
      heading: I18n.t("phrases.organisationStakeholders_upload_lastName"),
      editable: false,
      ...props
    })
  };
};

export const colImpactCount = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "impactCount",
      heading: I18n.t("phrases.stakeholderCapacity_impactCount"),
      editable: false,
      clickEditable: true,
      width: 200,
      ...props
    }),
    onCellDoubleClicked: params => {
      if (params.data.impactCount > 0) return SHOW_USER_IMPACT_COUNT_MODAL(params.data);
    }
  };
};

export const colActionCount = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "actionCount",
      heading: I18n.t("phrases.stakeholderCapacity_actionCount"),
      editable: false,
      clickEditable: true,
      width: 200,
      ...props
    }),
    onCellDoubleClicked: params => {
      if (params.data.actionCount > 0) return SHOW_USER_ACTION_COUNT_MODAL(params.data);
    }
  };
};

export const colStakeholderCount = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "stakeholderCount",
      heading: I18n.t("phrases.stakeholderCapacity_stakeholderCount"),
      editable: false,
      clickEditable: true,
      width: 200,
      ...props
    }),
    onCellDoubleClicked: params => {
      if (params.data.stakeholderCount > 0) return SHOW_STAKEHOLDER_COUNT_MODAL(params.data);
    }
  };
};
export const colAudienceCount = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "audienceCount",
      heading: I18n.t("phrases.stakeholderCapacity_audienceCount"),
      editable: false,
      clickEditable: true,
      width: 200,
      ...props
    }),
    onCellDoubleClicked: params => {
      if (params.data.audienceCount > 0) return SHOW_AUDIENCE_COUNT_MODAL(params.data);
    }
  };
};

export const colProjectCount = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "projectCount",
      heading: I18n.t("phrases.stakeholderCapacity_projectCount"),
      editable: false,
      clickEditable: true,
      width: 200,
      ...props
    }),
    onCellDoubleClicked: params => {
      if (params.data.projectCount > 0) return SHOW_PROJECT_TEAM_COUNT_MODAL(params.data);
    }
  };
};
