import { BaseModel } from "../util/BaseModel";
import { ITableRowModel, ITableRowConfig } from "./ITableRowModel";
import { action, makeObservable, observable } from "mobx";
import * as React from "react";
import { ITableAction } from "./ITableAction";
import { ITableHeaderModel } from "./ITableHeaderModel";
import { getUiActionComponent } from "../uiAction/UiAction";

export class TableRowModel extends BaseModel implements ITableRowModel {
  @observable.ref rowObject: any = null;
  @observable.ref headerCol: ITableHeaderModel[] = [];
  @observable isBulkMode: boolean = false;
  @observable isChecked: boolean = false;
  @observable.ref actions: ITableAction[] = [];
  @observable index: number = null;
  className: string = "";
  isDivTable: boolean = false;
  isAccordion: boolean = false;
  renderExpandContent: (row: ITableRowModel) => React.ReactNode = null;
  onRowClick: (row: ITableRowModel) => void = null;
  constructor(initOpts?: ITableRowConfig) {
    super();
    makeObservable(this);
    if (initOpts) {
      this.setFromConfig(initOpts);
    }
  }

  @action
  setFromConfig = (initOpts?: ITableRowConfig) => {
    this.rowObject = initOpts.rowObject || null;
    this.headerCol = initOpts.headerCol || [];
    this.isBulkMode = initOpts.isBulkMode || false;
    this.index = initOpts.index || null;
    this.isDivTable = initOpts.isDivTable || this.isDivTable;
    this.actions = initOpts.actions || [];
    this.className = initOpts.className || this.className;
    this.onRowClick = initOpts.onRowClick || this.onRowClick;
    this.renderExpandContent = initOpts.renderExpandContent || this.renderExpandContent;
    this.isAccordion = !!initOpts.renderExpandContent;
  };

  @action
  setIsChecked = (val: boolean) => {
    this.isChecked = val;
  };

  getActionComponents = () => {
    return this.actions.map(e => {
      return getUiActionComponent(e, this);
    });
  };
}
