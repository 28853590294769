import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { ChangeTeamCapacityModel } from "./ChangeTeamCapacity_model";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { GridView } from "../../../../../../core/grids/GridView_view";
import { ChangeTeamCapacityGrid_GridModel } from "./ChangeTeamCapacityGrid/ChangeTeamCapacity_Grid_GridModel";
import { Input } from "../../../../../../components/ui/_forms/Input";
import { Enums, UiSizes } from "../../../../../../enums";
import { IconSymbols } from "../../../../../../components/ui/Icon";
import { useFlightPathUser } from "../../../../../../core/auth_insight";
import { Panel } from "../../../../../../components/ui/Panel";
import { ChangeTeamCapacityChart } from "./ChangeTeamCapacityChart/ChangeTeamCapacityChart_view";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import I18n from "../../../../../../core/localization/I18n";

export interface Props {}

export const ChangeTeamCapacity: React.FC<Props> = observer(props => {
  const authUser = useFlightPathUser();
  const organisationId = useCurrentOrganisationId();
  const isFirstRender = useRef(true);
  const [model, setModel] = useState(() => new ChangeTeamCapacityModel(organisationId, authUser));
  const [gridModel] = useState(() => new ChangeTeamCapacityGrid_GridModel(organisationId));

  useEffect(() => {
    if (isFirstRender.current) {
      model.onMount();
      isFirstRender.current = false;
    }
    return () => {
      if (model.isComponentMounted) {
        model.onUnmount();
      }
    };
  }, [model]);

  useEffect(() => {
    if (organisationId !== model.organisationId) {
      setModel(new ChangeTeamCapacityModel(organisationId, authUser));
    }
  }, [model, organisationId, authUser]);

  return (
    <div className="capacity-view-wrapper col">
      <Panel.Panel
        className={`py-2 px-4 my-4 `}
        hasBorder={true}
        background={Panel.PanelBackgrounds.BG_LIGHT}
        hasBorderRadius={true}
      >
        {model.isLoading ? (
          <PositionedSpinner></PositionedSpinner>
        ) : (
          <ChangeTeamCapacityChart
            data={model.changeTeamMetrics || {}}
            transformer={data => ({
              data,
              tooltipLabelFn: item => {
                return `${item.indexValue.split("-").slice(1).join("")}`;
              },
              indexBy: e => {
                return `${e.id} - ${e.firstName} - ${e.lastName}`;
              },
              keys: ["impactCount", "actionCount", "stakeholderCount", "audienceCount", "projectCount"],
              colors: ["#19d3e0", "#faa082", "#ffc036", "#fdd886", "#61a1fa"],
              legendLabel: e => {
                return I18n.t(`phrases.${e.id}`);
              },
              leftAxisLabel: "Total count",
              groupType: "grouped",
              chartMargin: { bottom: 80 },
              bottomAxisValueFormat: (val: string) => ({
                value: val.split("-").splice(1).join(" "),
                maxLineLength: 18,
                maxLines: 3,
                truncateAtChar: 15
              }),
              bottomAxisProps: { legendOffset: 70 },
              enableGridX: true,
            })}
          ></ChangeTeamCapacityChart>
        )}
      </Panel.Panel>
      <Panel.Panel
        className={`capacity-grid-wrapper p-4 my-4 `}
        hasBorder={true}
        background={Panel.PanelBackgrounds.BG_LIGHT}
        hasBorderRadius={true}
      >
        <div className="row align-items-center pt-1 pb-1">
          <div className="col d-flex">
            <div className="col-10"></div>
            <div className="col-2">
              <div className="text-right">
                <Input
                  autoFocus={true}
                  size={UiSizes.XS}
                  onChange={model.setSearchText}
                  placeholder="Search"
                  icon={IconSymbols.Search}
                  iconSize={Enums.UiSizes.SM}
                  style={{ height: 32 }}
                />
              </div>
            </div>
          </div>
        </div>
        <GridView
          data={model.changeTeamMetrics}
          model={gridModel}
          isLoading={gridModel.isLoading}
          onCellClicked={function (cell: string, isEditMode?: boolean): void {}}
          onCellEditModeChange={function (cell: string, isEditMode: boolean): void {}}
          onSelectionChanged={model.updateData}
          users={[]}
          overlayNoRowsTemplate={`<div></div>`}
          isRowSelectableFn={model.isRowSelectable}
          onGridReady={model.onGridReady}
        />
      </Panel.Panel>
    </div>
  );
});
