import { BarDatum, ResponsiveBar } from "@nivo/bar";
import React, { createElement, useMemo } from "react";
import { useTooltip } from "@nivo/tooltip";
import { ChartUsage, GapAnalysisChart } from "../gapAnalysis";
import { Pill, PillTypes } from "../../ui/Pill";
import { AxisProps } from "@nivo/axes";

interface IChartMargin {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export interface IImpactAssessmentDataGroup {
  id: string;
  label: string;
  data: IImpactAssessmentDataObject[];
}

export interface IImpactAssessmentDataObject {
  id: string;
  label: string;
  value: number;
  color: string;
}

export interface ImpactAssessmentChartProps {
  data: IImpactAssessmentDataGroup[];
  containerWidth?: number;
  margin?: IChartMargin;
  keys: string[];
  onClick?: any;
  dataConfidence?: number;
  heading: string;
  gapAnalysisOnClick?: any;
  noDataLabel: string;
  bottomAxis?: AxisProps<any>;
}

const mapData = (data: IImpactAssessmentDataGroup[]): BarDatum[] => {
  let mappedData = data.map(e => createMappedDataObject(e.label, e.data));
  return mappedData;
};

const createMappedDataObject = (key: string, data: IImpactAssessmentDataObject[]) => {
  let groupData = { groupName: key };

  data.forEach(x => {
    groupData[x.label] = x.value;
    groupData[x.label + "Color"] = x.color;
  });

  return groupData;
};

const colours = () => {
  return ["hsl(259, 100%, 38%)", "hsl(259, 85%, 63%)", "hsl(259, 100%, 82%)"];
};

const BAR_MAX_WIDTH = 50;

const CustomBarComponent = props => {
  const { x, y, width, height, color, data } = props.bar;

  const w = width > BAR_MAX_WIDTH ? BAR_MAX_WIDTH : width;

  const renderTooltip = useMemo(
    () => () => createElement(props.tooltip, { ...props.bar, ...data }),
    [props.tooltip, props.bar, data]
  );

  const { showTooltipFromEvent, hideTooltip } = useTooltip();

  return (
    <rect
      x={x + width / 2 - w / 2}
      y={y}
      width={w}
      height={height}
      fill={color}
      onMouseMove={(event): void => {
        showTooltipFromEvent(renderTooltip(), event);
      }}
      onMouseLeave={(event): void => {
        hideTooltip();
      }}
      onClick={e => props.onClick(data, e)}
      cursor={props.onClick ? "pointer" : ""}
    />
  );
};

const getTspanGroups = (value: string, maxLineLength: number, maxLines: number = 2) => {
  const words = value.replace("/", " / ").split(" ");

  type linesAcc = {
    lines: string[];
    currLine: string;
  };

  //reduces the words into lines of maxLineLength
  const assembleLines: linesAcc = words.reduce(
    (acc: linesAcc, word: string) => {
      //if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
      if ((word + acc.currLine).length > maxLineLength && acc.currLine !== "") {
        return {
          lines: acc.lines.concat([acc.currLine]),
          currLine: word
        };
      }
      //otherwise add the word to the current line
      return {
        ...acc,
        currLine: acc.currLine + " " + word
      };
    },
    { lines: [], currLine: "" }
  );

  //add the ending state of current line (the last line) to lines
  const allLines = assembleLines.lines.concat([assembleLines.currLine]);

  const lines = allLines.slice(0, maxLines);
  let children: JSX.Element[] = [];
  let dy = 0;

  lines.forEach((lineText, i) => {
    children.push(
      <tspan x={0} dy={dy} key={i}>
        {lineText}
      </tspan>
    );
    //increment dy to render next line text below
    dy = 14;
  });

  return children;
};

const ShowNoDataLabel: React.FC<any> = ({ label }) => {
  return (
    <div className="no-data--container">
      <Pill type={PillTypes.OUTLINE_LIGHT_GRAY}>{label}</Pill>
    </div>
  );
};

export const ImpactAssessmentChart: React.FC<ImpactAssessmentChartProps> = props => {
  let mappedData = mapData(props.data);

  return (
    <div>
      <div style={{ width: "100%", height: "100%" }} className={"d-flex"}>
        <div className="flex-row" style={{ flexGrow: 1 }}>
          <h3 style={{ textTransform: "uppercase" }}>{props.heading}</h3>
        </div>
        <div style={{ height: 100, flexGrow: 2, textAlign: "right" }} className={"flex-row"}>
          <GapAnalysisChart
            dataConfidence={props.dataConfidence}
            heading={"Gap Analysis"}
            chartUsage={ChartUsage.Widget}
            onClick={props.gapAnalysisOnClick}
          />
        </div>
      </div>
      <div className="d-flex" style={{ height: 300, width: "100%" }}>
        {props.data && props.data.length === 0 && <ShowNoDataLabel label={props.noDataLabel} />}
        {props.data && props.data.length > 0 && (
          <ResponsiveBar
            data={mappedData}
            barComponent={CustomBarComponent}
            indexBy={"groupName"}
            keys={props.keys}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={colours()}
            axisLeft={{
              tickSize: 1,
              legendOffset: -40,
              format: e => {
                if (Math.floor(e) === e) {
                  return e;
                } else {
                  return "";
                }
              }
            }}
            margin={props.margin}
            enableLabel={false}
            onClick={props.onClick}
            axisBottom={{
              tickSize: 0,
              tickPadding: 15,
              tickRotation: props.bottomAxis?.tickRotation || 0,
              legendPosition: "middle",
              legendOffset: 32,
              truncateTickAt: props.bottomAxis?.truncateTickAt || 0,
              renderTick: ({ opacity, textAnchor, textBaseline, textX, textY, value, x, y }) => {
                return (
                  <g transform={`translate(${x},${y})`} style={{ opacity }}>
                    <text
                      textAnchor={textAnchor}
                      transform={`translate(${textX},${textY}) rotate(${props.bottomAxis?.tickRotation || 0})`}
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: "11px",
                        fill: "rgb(156,163,176)"
                      }}
                    >
                      {getTspanGroups(value, 15, 10)}
                    </text>
                  </g>
                );
              }
            }}
            theme={{
              axis: {
                ticks: {
                  line: {
                    stroke: "green"
                  },
                  text: {
                    fill: "hsl(218, 11%, 65%)"
                  }
                }
              },
              grid: {
                line: {
                  stroke: "hsl(0, 0%, 75%)",
                  strokeWidth: 1,
                  strokeDasharray: "4 4"
                }
              }
            }}
          ></ResponsiveBar>
        )}
      </div>
    </div>
  );
};
